/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

/*
async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}
*/

/*
async function getOctetStream(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/octet-stream',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}
*/

class Brand {
    constructor(context) {
        // this.url = `${context.serviceEndpoint}/brand/${context.alias}`;
        this.url = `${context.serviceEndpoint}/brandprofile`;
        this.requestHeaders = context.requestHeaders;
    }

    async getStatus(query) {
        return getJson(`${this.url}/status/brand`, query, { requestHeaders: this.requestHeaders });
    }

    async get(query) {
        return getJson(`${this.url}/get/brand`, query, { requestHeaders: this.requestHeaders });
    }

    // async search(query) {
    //     return getJson(`${this.url}/search/brand`, query, { requestHeaders: this.requestHeaders });
    // }
}

class Image {
    constructor(context) {
        // this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.url = `${context.serviceEndpoint}/brandprofile`;
        this.requestHeaders = context.requestHeaders;
        this.alias = context.alias;
    }

    async get(query) {
        return getJson(`${this.url}/get/image`, query, { requestHeaders: this.requestHeaders });
    }

    // async search(query) {
    //     return getJson(`${this.url}/search/image`, query, { requestHeaders: this.requestHeaders });
    // }

    // async download(query) {
    //     return getOctetStream(`${this.url}/download/image`, query, { requestHeaders: this.requestHeaders });
    // }
}

class Palette {
    constructor(context) {
        // this.url = `${context.serviceEndpoint}/account/${context.accountId}`;
        this.url = `${context.serviceEndpoint}/brandprofile`;
        this.requestHeaders = context.requestHeaders;
    }

    async get(query) {
        return getJson(`${this.url}/get/palette`, query, { requestHeaders: this.requestHeaders });
    }

    // async search(query) {
    //     return getJson(`${this.url}/search/palette`, query, { requestHeaders: this.requestHeaders });
    // }
}

class BrowserClient {
    constructor(context = {}) {
        this.brand = new Brand(context);
        this.image = new Image(context);
        this.palette = new Palette(context);
    }
}

export default BrowserClient;

export {
    Brand,
    Image,
    Palette,
};
