import Vue from 'vue';
import Vuex from 'vuex';

import client from '../sdk/client-factory';

Vue.use(Vuex);

/*
Do not redirect user from here (no $router.push). Respond to calling code
with appropriate indicators and let the calling code redirect as needed.
*/

export default new Vuex.Store({
    state: {
        focus: null,
        brandprofile: null, // brandprofile ALIAS indicating the current brandprofile that should be used
        brand: null, // object with brand info // TODO: this is redundant, use a GETTER based on brandprofile and brandmap
        brandMap: {}, // brandprofile alias => brand item (object)
        realmBrandprofileMap: {}, // realm alias => brandprofile alias
        requestBrandprofileMap: {}, // login request id => brandprofile alias (because it may depend on the client or app, or it may be the realm brandprofile)
        palette: null, // TODO: this is redudnant, use a GETTER based on brandprofile and palettemaap
        paletteMap: {}, // brandprofile alias => palette item (object)
        /*
        We use a default palette with greys to minimize the flicker that
        happens when the actual brand palette is finally loaded; if we
        use LoginFront brand colors, the flicker is very noticeable
        */
        defaultPalette: {
            text: '#000000', // black
            primary: '#BDBDBD', // grey lighten-1
            primaryText: '#ffffff', // white; text color that should be used when text is over the primary color
            secondary: '#E0E0E0', // grey lighten-2
            accent: '#B0BEC5', // blue-grey lighten-3
            background: '#F5F5F5', // grey lighten-4
        },

        isReady: false, // indicates that we loaded session info from server, so we know if user is authenticated; and if user is authenticated, that we've also loaded user info and account info from server
        realm: null, // realm alias, set by App.vue
        realmInfo: {}, // realm info, set by loadRealmInfo, which is called by App.vue whenever realm parameter in URL changes
        serviceContext: {}, // stripeTokenPublicKey
        // serviceVersion: {}, // version
        session: { isAuthenticated: false }, // isAuthenticated, notAfter, isCsrfGuardEnabled
        user: {}, // name, email, sessionIdleExpiresMillis
        interactionMap: {}, // id => interaction ( type: string, next: string, state: object )
        nav: { queue: [] }, // queue  with items pushed to it, so whenever we are done with something and want to know where to return to, we pop the last item from the queue and go there; and each item can have a function to determine if it's still valid (and the user should be directed there) or if it should be ignored (remove from the queue and proceed to next item)
        loadingMap: {},
        // noteList: [],
    },
    getters: {
        isLoading(state) {
            return Object.values(state.loadingMap).reduce((acc, item) => acc || item, false);
        },
        brandName(state) {
            return state.brand?.name ?? ''; // 'Loading...';
        },
        primaryColor(state) {
            let result;
            if (Array.isArray(state.palette?.content?.primary) && state.palette.content.primary.length > 0) {
                result = state.palette.content.primary[0].hex;
            } else {
                result = state.defaultPalette.primary;
            }
            // TODO: input validation that palette primary color is a valid hex color value or html color name
            return result;
        },
        primaryTextColor(state) {
            return state.defaultPalette.primaryText;
        },
        accentColor(state) {
            let result;
            if (Array.isArray(state.palette?.content?.accent) && state.palette.content.accent.length > 0) {
                result = state.palette.content.accent[0].hex;
            } else {
                result = state.defaultPalette.accent;
            }
            // TODO: input validation that palette accent color is a valid hex color value or html color name
            return result;
        },
        cardTitleBarTextStyle(state, getters) {
            return `color: ${getters.primaryTextColor}`;
        },
        cardTitleBarStyle(state, getters) {
            return `background-color: ${getters.primaryColor}`;
        },
        primaryButtonStyle(state, getters) {
            return `color: ${getters.primaryTextColor}; background-color: ${getters.primaryColor};`;
        },
        primaryIconStyle(state, getters) {
            return `color: ${getters.primaryColor};`;
        },
    },
    mutations: {
        focus(state, value) {
            state.focus = value;
        },
        ready(state) {
            console.log('vuex store: ready');
            state.isReady = true;
        },
        realm(state, value) {
            state.realm = value;
            console.log(`vuex set realm ${value}`);
        },
        brandprofile(state, brandprofile) {
            state.brandprofile = brandprofile;
        },
        brand(state, brand) {
            state.brand = brand;
        },
        brandMap(state, { brandprofile, brand }) {
            state.brandMap[brandprofile] = brand;
        },
        paletteMap(state, { brandprofile, palette }) {
            state.paletteMap[brandprofile] = palette;
        },
        /**
         *
         * @param {*} state
         * @param {*} param1 { realm, brandprofile }  where realm is a realm ALIAS, and brandprofile is a brandprofile ALIAS
         */
        realmBrandprofile(state, { realm, brandprofile }) {
            state.requestBrandprofileMap[realm] = brandprofile;
        },
        /**
         *
         * @param {*} state
         * @param {*} param1 { intent, requestId, brandprofile }  where intent is 'login' or 'recovery', requestId is a corresponding login or recovery request id, and brandprofile is a brandprofile ALIAS
         */
        requestBrandprofile(state, { intent, requestId, brandprofile }) {
            state.requestBrandprofileMap[`${intent}:${requestId}`] = brandprofile;
        },
        palette(state, palette) {
            state.palette = palette;
        },
        setServiceContext(state, contextInfo) {
            state.serviceContext = contextInfo;
        },
        // setServiceVersion(state, versionInfo) {
        //     state.serviceVersion = versionInfo;
        // },
        setSession(state, session) {
            state.session = session;
        },
        setUser(state, user) {
            state.user = user;
        },
        setRealmInfo(state, realmInfo) {
            state.realmInfo = realmInfo;
        },
        setInteraction(state, interaction) {
            state.interactionMap = { ...state.interactionMap, ...interaction };
        },
        setNav(state, nav) {
            state.nav = nav;
        },
        loading(state, progress) {
            state.loadingMap = { ...state.loadingMap, ...progress };
        },
        // noteList(state, values) {
        //     state.noteList = [...values];
        // },
    },
    actions: {
        async createAccount({ commit, dispatch, state }, accountInfo) {
            commit('loading', { createAccount: true });
            const response = await client.realm(state.realm).user.create(accountInfo);
            if (response.isCreated) {
                await dispatch('loadSession');
                if (state.session.isAuthenticated) {
                    await dispatch('loadUser');
                    // await dispatch('loadAccount');
                }
            }
            commit('loading', { createAccount: false });
            return response;
        },
        async logout({ commit, state }) {
            commit('loading', { logout: true });
            await client.realm(state.realm).authn.logout();
            // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
            commit('setSession', { isAuthenticated: false });
            commit('loading', { logout: false });
        },
        // async enableCsrfGuard({ commit, state }) {
        //     const csrfTokenResponse = await client.authn.createCsrfToken();
        //     if (csrfTokenResponse.token) {
        //         const csrfGuardToken = csrfTokenResponse.token;
        //         localStorage.setItem('csrfGuardToken', csrfGuardToken);
        //         commit('setSession', { ...state.session, isCsrfGuardEnabled: true, csrfGuardToken });
        //     }
        // },
        async refresh({ dispatch, commit, state }) {
            console.log('vuex store: refresh');
            // not displaying loading bar because we want this to be transparent
            try {
                if (state.realm) {
                    console.log(`vuex store: refresh with realm ${state.realm}`);
                    await dispatch('loadSession', { progressIndicator: false });
                    if (state.session.isAuthenticated) {
                        await dispatch('loadUser', { progressIndicator: false });
                    }
                    commit('ready');
                } else {
                    console.log('vuex store: refresh with no realm, clearing state');
                    commit('setRealmInfo', {});
                    commit('setSession', { isAuthenticated: false });
                    commit('setUser', {});
                }
            } catch (err) {
                console.error('vuex store: refresh failed');
            }
        },
        async loadSession({ commit, dispatch, state }, { progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadSession: true });
                }
                const sessionInfo = await client.realm(state.realm).authn.get();
                commit('setSession', sessionInfo);
                const { isAuthenticated, notAfter } = sessionInfo;
                if (isAuthenticated && notAfter && typeof notAfter === 'number' && notAfter > Date.now()) {
                    const delay = notAfter - Date.now();
                    console.log(`vuex store: scheduling session reload for ${delay} ms`);
                    setTimeout(() => {
                        console.log('vuex store: reloading session');
                        dispatch('loadSession');
                    }, delay);
                }
            } catch (err) {
                console.error('loadSession failed', err);
                commit('setSession', { fault: { type: 'read-failed' } });
            } finally {
                if (progressIndicator) {
                    commit('loading', { loadSession: false });
                }
            }
        },
        async loadUser({ commit, state }, { progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadUser: true });
                }
                const userInfo = await client.realm(state.realm).currentUser.get();
                commit('setUser', userInfo);
            } catch (err) {
                console.error('loadUser failed', err);
                commit('setUser', { fault: { type: 'read-failed' } });
            } finally {
                if (progressIndicator) {
                    commit('loading', { loadUser: false });
                }
            }
        },
        async loadRealmInfo({ commit, dispatch, state }, { progressIndicator = true } = {}) {
            try {
                if (progressIndicator) {
                    commit('loading', { loadRealmInfo: true });
                }
                const { realm } = state;
                if (realm) {
                    const realmInfo = await client.realm(realm).currentRealm.get();
                    console.log(`vuex: loadRealmInfo result ${JSON.stringify(realmInfo)}`);
                    commit('setRealmInfo', realmInfo);
                    commit('realmBrandprofile', { realm, brandprofile: realmInfo.brandprofile });
                    // now that we have the realm info, if the realm has a brandprofile then load the brand info
                    if (realmInfo.brandprofile) {
                        dispatch('switchBrand', { brandprofile: realmInfo.brandprofile });
                    }
                } else {
                    commit('setRealmInfo', {});
                }
            } catch (err) {
                console.error('loadRealmInfo failed', err);
                commit('setRealmInfo', { fault: { type: 'read-failed' } });
            } finally {
                if (progressIndicator) {
                    commit('loading', { loadRealmInfo: false });
                }
            }
        },
        /**
         *
         * @param {*} param0
         * @param {*} param1 object { brandprofile } where brandprofile is the brandprofile ALIAS indicating which brandprofile to use
         */
        async switchBrand({ commit, dispatch, state }, { brandprofile }) {
            if (!state.brandMap[brandprofile]) {
                await dispatch('loadBrand', { alias: brandprofile, mode: 'light', media_type: 'screen' });
            }
            if (!state.paletteMap[brandprofile]) {
                await dispatch('loadPalette', { alias: brandprofile, mode: 'light', media_type: 'screen' });
            }
            commit('brand', state.brandMap[brandprofile]); // TODO: this is redundant when we have 'brandprofile' in state; we need to have a GETTER that automatically returns brandmap[brandprofile] based on the current brandprofile
            commit('palette', state.paletteMap[brandprofile]); // TODO: this is redundant when we have 'brandprofile' in state; we need to have a GETTER that automatically returns palettemap[brandprofile] based on the current brandprofile
            commit('brandprofile', brandprofile);
        },
        // async editSession({ commit }, sessionInfo) {
        //     commit('loading', { editSession: true });
        //     let isEdited = false;
        //     try {
        //         const newSessionInfo = await client.authn.edit(sessionInfo);
        //         commit('setSession', newSessionInfo);
        //         isEdited = true;
        //     } catch (err) {
        //         console.log('editSession error: %o', err);
        //     }
        //     commit('loading', { editSession: false });
        //     return isEdited;
        // },
        async editCurrentUser({ commit, state }, userInfo) {
            try {
                commit('loading', { editCurrentUser: true });
                const { isEdited } = await client.realm(state.realm).currentUser.edit(userInfo);
                // https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
                const newUserInfo = { ...state.user, info: { ...state.user.info, ...userInfo } };
                commit('setUser', newUserInfo);
                return isEdited;
            } catch (err) {
                console.error('editCurrentUser error', err);
                return false;
            } finally {
                commit('loading', { editCurrentUser: false });
            }
        },
        async createInteraction({ commit, state }, interaction) {
            commit('loading', { createInteraction: true });
            console.log('store: createInteraction %o', interaction);
            const response = await client.realm(state.realm).interaction.create(interaction);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { createInteraction: false });
            return response;
        },
        async editInteraction({ commit, state }, { interactionId, message }) {
            commit('loading', { editInteraction: true });
            console.log('store: editInteraction %s', interactionId);
            const response = await client.realm(state.realm).interaction.edit(interactionId, message);
            if (response.id) {
                commit('setInteraction', { [response.id]: response });
            }
            commit('loading', { editInteraction: false });
            return response;
        },
        async loadInteraction({ commit, state }, interactionId) {
            commit('loading', { loadInteraction: true });
            const response = await client.realm(state.realm).interaction.get(interactionId);
            commit('setInteraction', { [interactionId]: response });
            commit('loading', { loadInteraction: false });
            return response;
        },
        queueNavItem({ commit, state }, item) {
            const queue = [...state.nav.queue];
            queue.push({ path: item.path, query: item.query || {}, hash: item.hash || '' });
            commit('setNav', { queue });
        },
        nextNavItem({ commit, state }) {
            try {
                const queue = [...state.nav.queue];
                if (queue.length > 0) {
                    const next = queue.splice(queue.length - 1, 1);
                    commit('setNav', { queue });
                    return next[0];
                }
                if (state.session.isAuthenticated) {
                    return { path: '/dashboard' };
                }
                return { path: '/' };
            } catch (err) {
                console.log('forward error: %o', err);
                return { path: '/' };
            }
        },
        async loadBrand({ commit }, { alias }) {
            try {
                commit('loading', { loadBrand: true });
                const response = await client.brandprofile().brand.get({ alias });
                // commit('brand', response);
                commit('brandMap', { brandprofile: alias, brand: response });
            } catch (err) {
                console.error('loadBrand failed', err);
                // commit('brand', null);
            } finally {
                commit('loading', { loadBrand: false });
            }
        },
        async loadPalette({ commit }, { alias, mode = 'light' }) {
            try {
                commit('loading', { loadPalette: true });
                const match = {
                    alias,
                    mode,
                };
                const response = await client.brandprofile().palette.get(match);
                // commit('palette', response);
                commit('paletteMap', { brandprofile: alias, palette: response });
            } catch (err) {
                console.error('loadPalette failed', err);
                // commit('palette', null);
            } finally {
                commit('loading', { loadPalette: false });
            }
        },
    },
});
