/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class Service {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }
    /*
    async getContext() {
        return getJson(`${this.url}/context`);
    }
    */

    /**
     * Access control: this API is only available to service administrators
     */
    async getVersion() {
        return getJson(`${this.url}/version`, null, { requestHeaders: this.requestHeaders });
    }
}

class AppLink {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/realm/${context.realm}`;
        this.requestHeaders = context.requestHeaders;
    }

    async search(query) {
        return getJson(`${this.url}/app-link/search`, query, { requestHeaders: this.requestHeaders });
    }

    async redirect(request) {
        return postJsonAcceptJson(`${this.url}/app-link/redirect`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class Authn {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/realm/${context.realm}`;
        this.requestHeaders = context.requestHeaders;
    }

    // request can be { token } to start, or { id } to load the current status
    async setup(request) {
        return postJsonAcceptJson(`${this.url}/authn/setup`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(request) {
        return getJson(`${this.url}/authn/session`, request, { requestHeaders: this.requestHeaders });
    }

    async startLogin(request) {
        return postJsonAcceptJson(`${this.url}/authn/start/login`, request, null, { requestHeaders: this.requestHeaders });
    }

    async getLoginRequestStatus(id) {
        return getJson(`${this.url}/authn/login`, { id }, { requestHeaders: this.requestHeaders });
    }

    async resetLoginRequest(id) {
        return postJsonAcceptJson(`${this.url}/authn/login/reset`, null, { id }, { requestHeaders: this.requestHeaders });
    }
    // async loginWithUserAlias(request) {
    //     return postJsonAcceptJson(`${this.url}/authn/login/alias`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async loginWithUserEmail(id, request) {
        return postJsonAcceptJson(`${this.url}/authn/login/email`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async loginWithUserPseudonym(id, request) {
        return postJsonAcceptJson(`${this.url}/authn/login/pseudonym`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async loginWithPassword(id, request) {
        return postJsonAcceptJson(`${this.url}/authn/login/password`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async loginWithLoginShield(id, request) {
        return postJsonAcceptJson(`${this.url}/authn/login/loginshield`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async logout(request = {}) {
        return postJsonAcceptJson(`${this.url}/authn/logout`, request, null, { requestHeaders: this.requestHeaders });
    }

    async edit(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/authn/edit`, request, null, { requestHeaders: this.requestHeaders });
    }

    async changePassword(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/authn/edit/password`, request, null, { requestHeaders: this.requestHeaders });
    }

    async checkPasswordQuality(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/authn/check-password-quality`, request, null, { requestHeaders: this.requestHeaders });
    }

    async activateEmailToken(request) {
        return postJsonAcceptJson(`${this.url}/authn/activate-email-token`, request, null, { requestHeaders: this.requestHeaders });
    }

    async startRecovery(request) {
        return postJsonAcceptJson(`${this.url}/authn/start/recovery`, request, null, { requestHeaders: this.requestHeaders });
    }

    async recoverAccess(id, request) {
        return postJsonAcceptJson(`${this.url}/authn/recovery`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async getRecoverAccessRequestStatus(id) {
        return getJson(`${this.url}/authn/recovery`, { id }, { requestHeaders: this.requestHeaders });
    }

    /**
     * The realm's authentication policy
    */
    async getPolicy(query) {
        return getJson(`${this.url}/authn/policy`, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * The user's authentication settings
    */
    async getUserSettings(query) {
        return getJson(`${this.url}/authn/settings`, query, { requestHeaders: this.requestHeaders });
    }

    async getLoginShieldSettings(query) {
        return getJson(`${this.url}/authn/settings/loginshield`, query, { requestHeaders: this.requestHeaders });
    }

    async editLoginShieldSettings(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/authn/edit/loginshield`, request, null, { requestHeaders: this.requestHeaders });
    }
}

class CurrentRealm {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/realm/${context.realm}`;
        this.requestHeaders = context.requestHeaders;
    }

    // public
    async get(query) {
        return getJson(`${this.url}/realm`, query, { requestHeaders: this.requestHeaders });
    }

    // public
    async getImage(query) {
        return getJson(`${this.url}/realm/image`, query, { requestHeaders: this.requestHeaders });
    }

    // async edit(id, request) {
    //     // NOTE: you only need to specify the attributes that should be changed
    //     return postJsonAcceptJson(`${this.url}/edit`, request, { id }, { requestHeaders: this.requestHeaders });
    // }
}

class CurrentUser {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/realm/${context.realm}`;
        this.requestHeaders = context.requestHeaders;
    }

    async get() {
        return getJson(`${this.url}/current-user`, null, { requestHeaders: this.requestHeaders });
    }

    async edit(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/current-user/edit`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/current-user/delete`, null, null, { requestHeaders: this.requestHeaders });
    }
}

class User {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/realm/${context.realm}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/user/create`, request, null, { requestHeaders: this.requestHeaders });
    }

    async invite(request) {
        return postJsonAcceptJson(`${this.url}/user/invite`, request, null, { requestHeaders: this.requestHeaders });
    }

    // request like { email (string), agreeToTerms (boolean) }
    async activate(request) {
        return postJsonAcceptJson(`${this.url}/user/activate`, request, null, { requestHeaders: this.requestHeaders });
    }

    async list(request) {
        return getJson(`${this.url}/user/search`, request, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/user`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(id, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/user/edit`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async delete({ id }) {
        return postJsonAcceptJson(`${this.url}/user/delete`, null, { id }, { requestHeaders: this.requestHeaders });
    }
}

class UserAuthzToken {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/realm/${context.realm}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/user-authz-token/create`, request, null, { requestHeaders: this.requestHeaders });
    }

    async list() {
        return getJson(`${this.url}/user-authz-token/search`, null, { requestHeaders: this.requestHeaders });
    }

    async delete({ id }) {
        return postJsonAcceptJson(`${this.url}/user-authz-token/delete`, null, { id }, { requestHeaders: this.requestHeaders });
    }
}

class UserAuthzCode {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/realm/${context.realm}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/user-authz-code/create`, request, null, { requestHeaders: this.requestHeaders });
    }

    async list() {
        return getJson(`${this.url}/user-authz-code/search`, null, { requestHeaders: this.requestHeaders });
    }

    async delete({ id }) {
        return postJsonAcceptJson(`${this.url}/user-authz-code/delete`, null, { id }, { requestHeaders: this.requestHeaders });
    }
}

class Interaction {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/realm/${context.realm}`;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/interaction/create`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/interaction`, { id }, { requestHeaders: this.requestHeaders });
    }

    async resume(token) {
        return getJson(`${this.url}/interaction/resume`, { token }, { requestHeaders: this.requestHeaders });
    }

    async edit(id, message) {
        return postJsonAcceptJson(`${this.url}/interaction/edit`, message, { id }, { requestHeaders: this.requestHeaders });
    }

    async getTokenStatus(tokenId) {
        console.log('getTokenStatus');
        return getJson(`${this.url}/interaction/token/status`, { tokenId }, { requestHeaders: this.requestHeaders });
    }
}

class Webauthz {
    constructor(context) {
        this.url = `${context.serviceEndpoint}/realm/${context.realm}`;
        this.requestHeaders = context.requestHeaders;
    }

    async getAccessPrompt(id) {
        return getJson(`${this.url}/webauthz/prompt`, { id });
    }

    async grantAccess(id, permit = {}) {
        return postJsonAcceptJson(`${this.url}/webauthz/prompt`, { id, submit: 'grant', permit });
    }

    async denyAccess(id) {
        return postJsonAcceptJson(`${this.url}/webauthz/prompt`, { id, submit: 'deny' });
    }
}

/*
const context = {
    endpoint,
    serviceEndpoint: `${endpoint}/service`,
    requestHeaders: {},
};
*/
class BrowserClient {
    constructor(context = {}) {
        this.authn = new Authn(context);
        this.applink = new AppLink(context);
        this.currentRealm = new CurrentRealm(context);
        this.currentUser = new CurrentUser(context);
        this.interaction = new Interaction(context);
        this.service = new Service(context);
        this.user = new User(context);
        this.userAuthzToken = new UserAuthzToken(context);
        this.userAuthzCode = new UserAuthzCode(context);
        this.webauthz = new Webauthz(context);
    }
}

export default BrowserClient;

export {
    AppLink,
    Authn,
    CurrentRealm,
    CurrentUser,
    Interaction,
    Service,
    User,
    UserAuthzToken,
    UserAuthzCode,
    Webauthz,
};
