import Vue from 'vue';

/*
Vue custom events do not propagate to parent components like DOM events.
This plugin provides a global event bus using a separate instance of Vue.
Use the global event bus when you need to send and receive messages from
components that are not directly referencing each other, for example a
reusable UI component could use `this.$bus.$emit` to trigger a snackbar
that is defined in the main app component. Continue to use regular Vue
`this.$emit` when the message is sent by a component to its parent component.
To listen to an event from anywhere, use `this.$bus.$on('event', function)`.
*/

function setup() {
    const bus = new Vue();
    Object.defineProperty(Vue.prototype, '$bus', {
        value: bus,
        writable: false,
        enumerable: false,
        configurable: false,
    });
}

export default { setup };
