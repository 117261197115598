import Vue from 'vue';

/**
 * A mix-in for the Vue object. Given an input field reference, activates the field.
 * For a text input that means focusing the field.
 *
 * @param {*} ref the input; for example "name" in <v-text-field ref="name"></v-text-field>
 */
function activateInput(ref) {
    this.$nextTick(() => {
        setTimeout(() => {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        }, 1);
    });
}

function setup() {
    Object.defineProperty(Vue.prototype, '$activateInput', {
        value: activateInput,
        writable: false,
        enumerable: false,
        configurable: false,
    });
}

export default { setup };
