<template>
  <component :is="layout">
    <SnackBar :attr="snackbarAttr" />
    <template v-if="isPublic || (isAuthenticatedReady && isAuthenticated)">
      <router-view />
    </template>
    <template v-if="!isPublic && !isAuthenticatedReady">
      <v-row style="height: 100%" align="center" justify="center">
        <div class="app-splash-loader" />
      </v-row>
    </template>
    <template v-if="!isPublic && isAuthenticatedReady && !isAuthenticated">
      <v-row style="height: 100%" align="center" justify="center">
        <v-card elevation="4">
          <v-toolbar short flat color="indigo white--text">
            <v-toolbar-title>Login to continue</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            The requested resource is only available to authenticated users.
          </v-card-text>
          <v-card-actions class="my-2">
            <v-spacer />
            <v-btn elevation="4" color="indigo white--text" @click="login">
              Login
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-row>
    </template>
  </component>
</template>

<script>
import { mapState } from 'vuex';
// import '@/assets/font/NunitoSans/NunitoSans.css'
import SnackBar from '@/components/SnackBar.vue';

export default {
    name: 'App',

    components: {
        SnackBar,
    },

    data: () => ({
        snackbarAttr: {},
    }),

    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isPublic() {
            return this.$route.meta.isPublic ?? false;
        },
        layout() {
            return this.$route.meta.layout ?? 'main-layout';
        },
    },

    watch: {
        '$route.params.realm': function onChangedRealm(value) {
            console.log(`app.vue, realm changed to ${value}`);
            this.$store.commit('realm', value);
            this.$nextTick(() => {
                this.$store.dispatch('loadRealmInfo');
                this.$store.dispatch('refresh');
            });
        },
    },

    created() {
        // call when tab is selected
        window.onfocus = () => {
            this.$store.dispatch('refresh');
            this.$store.commit('focus', Date.now()); // any components that want to refresh on focus can watch for changes to this value
        };

        // listen for snackbar events coming from any component
        this.$bus.$on('snackbar', (attr) => { this.snackbarAttr = attr; });
    },

    mounted() {
        console.log(`app.vue: mounted with realm ${this.$route.params.realm}`);
        this.$store.commit('realm', this.$route.params.realm);
        this.$nextTick(() => {
            this.$store.dispatch('loadRealmInfo');
            this.$store.dispatch('refresh');
        });
    },

    methods: {
        async login() {
            const { fullPath } = this.$router.currentRoute;

            // // create a new interaction so after login the user will be
            // // redirected back to the current page
            // const createInteractionResponse = await this.$client.interaction.create({
            //     type: 'require_login',
            //     state: {
            //         redirect: fullPath,
            //     },
            // });
            // if (typeof createInteractionResponse !== 'object' || createInteractionResponse.error || !createInteractionResponse.isCreated || !createInteractionResponse.id) {
            //     this.loginError = true;
            //     return;
            // }

            // this.$router.push({ path: '/login', query: { i: createInteractionResponse.id } });
            this.$router.push({ name: 'realm-login', params: { realm: this.$route.params.realm }, query: { next: fullPath } });
        },
    },
};
</script>

<style lang="css">
html {
  overflow-y: auto; /* reference: https://vuetifyjs.com/en/getting-started/frequently-asked-questions/ "The scrollbar is showing even though my content is not overflowing vertically" */
}

.app-splash-loader {
    border: 4px solid #F5F5F5; /* grey lighten-4, to make smoother transition to brand color palette; prior color was #9FA8DA indigo lighten-3 */
    border-top: 4px solid #BDBDBD; /* grey lighten-1, to make smoother transition to brand color palette; prior color was #3F51B5 indigo */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.0s linear infinite;
    margin: auto;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>
