import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/FrontPage.vue'),
        },
        {
            path: '/realm/:realm',
            name: 'realm-front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "realm-front" */ '../views/realm/FrontPage.vue'),
        },
        {
            path: '/realm/:realm/dashboard',
            name: 'realm-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-dashboard" */ '../views/realm/RealmDashboard.vue'),
        },
        {
            path: '/realm/:realm/contact',
            name: 'realm-contact',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-contact" */ '../views/realm/RealmContact.vue'),
        },
        // TODO: is this really needed here? maybe only admin service needs interactions ... since our email links go to /realm/:realm/email , and not here.
        {
            path: '/realm/:realm/interaction',
            name: 'realm-interaction',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-interaction" */ '../views/realm/ResumeInteraction.vue'),
        },
        {
            path: '/realm/:realm/setup',
            name: 'realm-profile-setup',
            meta: { layout: 'realm-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "realm-profile-setup" */ '../views/realm/UserSetup.vue'),
        },
        {
            path: '/realm/:realm/login',
            name: 'realm-login',
            meta: { layout: 'realm-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "realm-login" */ '../views/realm/SignIn.vue'),
        },
        {
            path: '/realm/:realm/sso',
            name: 'realm-sso',
            meta: { layout: 'realm-layout' },
            component: () => import(/* webpackChunkName: "realm-sso" */ '../views/realm/SsoActivity.vue'),
        },
        {
            path: '/realm/:realm/email',
            name: 'realm-email',
            meta: { layout: 'realm-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "realm-email" */ '../views/realm/RealmEmail.vue'),
        },
        {
            path: '/realm/:realm/preferences',
            name: 'realm-user-preferences',
            meta: { layout: 'realm-layout' },
            component: () => import(/* webpackChunkName: "realm-user-preferences" */ '../views/realm/UserPreferences.vue'),
        },
        {
            path: '/realm/:realm/preferences/authn',
            name: 'realm-user-preferences-authn',
            meta: { layout: 'realm-layout' },
            component: () => import(/* webpackChunkName: "realm-user-preferences-authn" */ '../views/realm/PreferencesAuthn.vue'),
        },
        {
            path: '/realm/:realm/access-recovery',
            name: 'realm-access-recovery',
            meta: { layout: 'realm-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "realm-access-recovery" */ '../views/realm/AccessRecovery.vue'),
        },
        // TODO: is this really needed here?  maybe only admin service does the webauthz prompt
        {
            path: '/realm/:realm/webauthz/prompt',
            name: 'realm-webauthz-prompt',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "realm-webauthz-prompt" */ '../views/webauthz/WebauthzPrompt.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
