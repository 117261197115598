import BrandProfileClient from './client/brandprofile';
import RealmClient from './client/realm';

/* eslint-disable dot-notation */

const otherClientMap = {}; // realm -> client configured for that realm
const realmClientMap = {}; // realm -> client configured for that realm
const client = {
    brandprofile() {
        if (!otherClientMap['brandprofile']) {
            otherClientMap['brandprofile'] = new BrandProfileClient({ endpoint: '/', serviceEndpoint: '/api/browser' });
        }
        return otherClientMap['brandprofile'];
    },
    realm(alias) {
        if (!realmClientMap[alias]) {
            realmClientMap[alias] = new RealmClient({ endpoint: '/', serviceEndpoint: '/api/browser', realm: alias });
        }
        return realmClientMap[alias];
    },
};

export default client;
