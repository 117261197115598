<template>
  <nav class="no-print">
    <v-app-bar fixed fade-on-scroll elevation="1" dense color="white" app>
      <router-link v-if="!isAuthenticated" :to="redirectToMain" style="text-decoration: none">
        <v-toolbar-title class="indigo--text text-overline" style="font-size: 16px!important;">
          Xentri
        </v-toolbar-title>
      </router-link>
      <router-link v-if="isAuthenticated" :to="redirectToDashboard" style="text-decoration: none">
        <v-toolbar-title class="indigo--text text-overline" style="font-size: 16px!important;">
          Xentri
        </v-toolbar-title>
      </router-link>
      <!-- 240x48, 180x36, 120x24, 90x18 -->
      <!-- <router-link :to="redirectToMain">
                <v-img
                alt="Webauthz"
                class="shrink mr-2"
                contain
                :src="require('@/assets/logo/Webauthz_nobg_240x48.png')"
                transition="scale-transition"
                width="120"
                height="24"
                />
                </router-link> -->
      <v-progress-linear
        :active="isLoading"
        :indeterminate="isLoading"
        absolute
        bottom
        :color="progressBarColor"
      />
      <v-spacer />
      <v-menu v-model="helpMenu" offset-y open-on-click open-on-hover close-delay="100">
        <template #activator="{ on, attrs }">
          <v-btn icon color="indigo" v-bind="attrs" v-on="on">
            <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
            <font-awesome-icon :icon="['fas', 'question']" fixed-width />
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            Questions or comments?
            <a :href="contactURL" target="_blank" rel="noopener noreferrer">Contact us!</a>
          </v-card-text>
        </v-card>
      </v-menu>
      <div v-if="!isAuthenticated">
        <div v-show="$vuetify.breakpoint.smAndUp">
          <v-tabs slider-color="indigo" right>
            <!-- eslint-disable-next-line vue/no-constant-condition -->
            <v-tab v-show="false" :to="redirectToMain" />
            <!-- <v-tab to="/about">About</v-tab>
                        <v-tab to="/login">Login</v-tab> -->
          </v-tabs>
        </div>
        <div v-show="$vuetify.breakpoint.xsOnly">
          <v-menu offset-y>
            <template #activator="{ on }">
              <v-btn text v-on="on">
                <font-awesome-icon right class="indigo--text" icon="bars" fixed-width />
              </v-btn>
            </template>
            <v-tabs vertical slider-color="indigo">
              <!-- eslint-disable-next-line vue/no-constant-condition -->
              <v-tab v-show="false" :to="redirectToMain" />
              <!-- <v-tab to="/about">About</v-tab>
                            <v-tab to="/login">Login</v-tab> -->
            </v-tabs>
          </v-menu>
        </div>
      </div>
      <div v-if="isAuthenticated">
        <v-tabs slider-color="indigo">
          <!-- Show toolbar buttons on large screens -->
          <!-- eslint-disable-next-line vue/no-constant-condition -->
          <v-tab v-show="false" :to="redirectToDashboard" />
          <!-- <v-tab to='/user' v-show="$vuetify.breakpoint.smAndUp">Users</v-tab>
                    <v-tab to='/group' v-show="$vuetify.breakpoint.smAndUp">Groups</v-tab>
                    <v-tab to='/access-policy' v-show="$vuetify.breakpoint.smAndUp">Access</v-tab> -->
          <v-menu offset-y>
            <template #activator="{ on }">
              <v-tab class="indigo--text" v-on="on">
                <UserAvatar :attr="mainProfile" :size="36" />
                <font-awesome-icon icon="caret-down" class="pl-2 indigo--text" fixed-width size="1x" />
              </v-tab>
            </template>
            <v-list class="ma-0 pa-0">
              <div>
                <v-tabs vertical slider-color="indigo" class="menutabs">
                  <!-- eslint-disable-next-line vue/no-constant-condition -->
                  <v-tab v-show="false" :to="redirectToDashboard" />
                  <!-- Show these in menu if screen is too small to fit buttons -->
                  <!-- <v-tab to='/user' v-show="$vuetify.breakpoint.xsOnly">Users</v-tab>
                                    <v-tab to='/group' v-show="$vuetify.breakpoint.xsOnly">Groups</v-tab>
                                    <v-tab to='/access-policy' v-show="$vuetify.breakpoint.xsOnly">Access</v-tab> -->
                  <!-- Show these in menu always -->
                  <v-tab :to="redirectToPreferences">
                    Preferences
                  </v-tab>
                  <!-- <v-tab to='/account'>Accounts</v-tab> -->
                  <!-- <v-tab to="/about">About</v-tab> -->
                  <v-divider />
                  <v-tab @click="onLogout">
                    Logout
                  </v-tab>
                </v-tabs>
              </div>
            </v-list>
          </v-menu>
        </v-tabs>
      </div>
    </v-app-bar>
  </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import UserAvatar from '@/components/UserAvatar.vue';

export default {
    components: {
        UserAvatar,
    },
    data: () => ({
        realm: null,
    }),
    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            user: (state) => state.user,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        mainProfile() {
            return { name: this.user.name, email: this.user.email };
        },
        redirectToMain() {
            if (this.realm) {
                return { name: 'realm-front', params: { realm: this.realm } };
            }
            return { name: 'front' };
        },
        redirectToDashboard() {
            if (this.realm) {
                return { name: 'realm-dashboard', params: { realm: this.realm } };
            }
            return { name: 'front' };
        },
        redirectToPreferences() {
            if (this.realm) {
                return { name: 'realm-user-preferences', params: { realm: this.realm } };
            }
            return { name: 'front' };
        },
        contactURL() {
            const websiteURL = new URL(process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://loginfront.com');
            websiteURL.pathname = '/contact/';
            return websiteURL.toString();
        },
        progressBarColor() {
            return this.primaryColor;
        },
    },

    watch: {
        '$route.params.realm': function onChangeRealm(value) {
            this.realm = value;
        },
    },

    mounted() {
        console.log(`navbar route params ${JSON.stringify(this.$route.params)}`);
        if (this.$route.params.realm) {
            console.log(`navabar realm ${this.$route.params.realm}`);
            this.realm = this.$route.params.realm;
        } else {
            console.log('navbar no realm');
        }
    },
    methods: {
        async onLogout() {
            if (this.$route.params.realm) {
                console.log('onLogout: redirecting to front page');
                await this.$store.dispatch('logout');
                if (this.$route.name !== 'realm-front') {
                    this.$router.push({ name: 'realm-front', params: { realm: this.$route.params.realm } });
                }
            } else {
                console.log('navbar no realm');
            }
        },
    },
};
</script>

<style>
    @media print {
        .no-print {
            display: none !important;
        }
    }
    @media screen {
        div.menutabs div[role="tablist"] { width: 100%; }
        div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
    }
</style>
