import Vue from 'vue';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faUser as faUserSolid,
    faEnvelope as faEnvelopeSolid,
    faPhone, faCheck, faFile,
    faCheckSquare, faPlus, faTrash, faSave, faCamera,
    faTimes as faTimesSolid,
    faTimesCircle as faTimesCircleSolid,
    faSort, faSortDown, faCaretDown,
    faBriefcase, faCompress, faDesktop, faShieldAlt, faHeart, faUserSecret,
    faBuilding, faFingerprint, faCode, faBars, faUserCircle,
    faPencilAlt, faSearch, faEdit, faInfoCircle,
    faLock as faLockSolid,
    faUnlock as faUnlockSolid,
    faCloudDownloadAlt,
    faEye as faEyeSolid,
    faChartLine as faChartLineSolid,
    faFolder as faFolderSolid,
    faAngleRight, faAngleLeft, faAngleDoubleRight, faAngleDoubleLeft,
    faMinusSquare, faPaperclip, faArrowLeft,
    faExclamationTriangle as faExclamationTriangleSolid,
    faExclamation as faExclamationSolid,
    faExclamationCircle as faExclamationCircleSolid,
    faQuestionCircle as faQuestionCircleSolid,
    faQuestion as faQuestionSolid,
    faChevronLeft as faChevronLeftSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare,
} from '@fortawesome/free-regular-svg-icons';
// import {
//     faWordpress as faWordpressBrand,
// } from '@fortawesome/free-brands-svg-icons';
import { faExclamationSquare as faExclamationSquareSolid } from '@/fontawesome-icons/faExclamationSquare';

function setup() {
    Vue.component('FontAwesomeIcon', FontAwesomeIcon);
    Vue.component('FontAwesomeLayers', FontAwesomeLayers);
    Vue.component('FontAwesomeLayersText', FontAwesomeLayersText);
    library.add(
        faUserSolid,
        faEnvelopeSolid,
        faPhone,
        faCheck,
        faFile,
        faCheckSquare,
        faSquare,
        faPlus,
        faTrash,
        faSave,
        faCamera,
        faBriefcase,
        faCompress,
        faDesktop,
        faShieldAlt,
        faTimesSolid,
        faTimesCircleSolid,
        faSort,
        faSortDown,
        faCaretDown,
        faHeart,
        faBuilding,
        faUserSecret,
        faFingerprint,
        faCode,
        faBars,
        faUserCircle,
        faPencilAlt,
        faSearch,
        faEdit,
        faInfoCircle,
        faLockSolid,
        faUnlockSolid,
        faCloudDownloadAlt,
        faEyeSolid,
        faChartLineSolid,
        faFolderSolid,
        faAngleRight,
        faAngleLeft,
        faAngleDoubleRight,
        faAngleDoubleLeft,
        faMinusSquare,
        faPaperclip,
        faArrowLeft,
        faExclamationTriangleSolid,
        faExclamationSolid,
        faExclamationCircleSolid,
        faExclamationSquareSolid,
        faQuestionCircleSolid,
        faQuestionSolid,
        faChevronLeftSolid,
    );
}

function mapFontAwesomeIcons(iconMap) {
    const output = {};
    Object.entries(iconMap).forEach(([key, value]) => {
        output[key] = {
            component: FontAwesomeIcon,
            props: {
                icon: value,
            },
        };
    });
    return output;
}

export default { mapFontAwesomeIcons, setup };
