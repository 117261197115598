import Vue from 'vue';
import App from './App.vue';
import activateInput from './plugins/activate-input';
import fontawesome from './plugins/fontawesome';
import ifdev from './plugins/ifdev';
import layouts from './plugins/layouts';
import router from './plugins/router';
import store from './plugins/store';
import vuetify from './plugins/vuetify';
import track from './plugins/track';
import client from './plugins/client';
import bus from './plugins/bus';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import './registerServiceWorker';

activateInput.setup();
fontawesome.setup();
ifdev.setup();
layouts.setup();
track.setup();
client.setup();
bus.setup();

Vue.config.productionTip = false;

// https://github.com/vuetifyjs/vuetify/issues/9999
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <svg>.';
Vue.config.warnHandler = (msg, vm, trace) => {
    // `trace` is the component hierarchy trace
    if (msg === ignoreWarnMessage) {
        return;
    }
    console.warn(`${msg} vm: ${vm} trace: ${trace}`);
};

/*
Example input:
{
    first: ['fas', 'angle-double-left'],
    prev: ['fas', 'angle-left'],
}
Example output:
{
    first: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'angle-double-left'],
        },
    },
    prev: {
        component: FontAwesomeIcon,
        props: {
            icon: ['fas', 'angle-left'],
        },
    },
}
*/

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
