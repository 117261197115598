import Vue from 'vue';

import client from '../sdk/client-factory';

function setup() {
    // const endpoint = new URL(window.location).origin;
    Object.defineProperty(Vue.prototype, '$client', {
        value: client,
        writable: false,
        enumerable: false,
        configurable: false,
    });
}

export default { setup };
