<template>
  <v-app>
    <!-- <NavBar/> -->
    <!-- <v-main style="background-color:#efefef; height: 100%;" class="ma-0 print">
      <v-container class="ma-0 pa-0" fluid style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
          <slot/>
        </v-col>
      </v-container>
    </v-main> -->
    <!-- class="py-3 px-3"   cant do this AND margin , it messes up    margin-top: 48px; margin-bottom: 88px; -->
    <v-main style="background-color: #efefef; height: 100%;" class="print">
      <!-- <v-container fluid>
            <slot/>
        </v-container> -->
      <v-container class="ma-0 pa-0" fluid style="height: 100%;">
        <v-row no-gutters align="center" justify="center" style="height: 100%;">
          <v-col cols="12" class="ma-0 pa-0">
            <v-row no-gutters align="center" justify="center" style="width: 300px; margin: auto;">
              <template v-if="websiteURL">
                <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
                <a style="display: inline-block; text-decoration: none;" :href="websiteURL"><RealmLogoImage :brandprofile="brandprofile" :max-height="48" contain /></a>
              </template>
              <template v-else>
                <RealmLogoImage :brandprofile="brandprofile" :max-height="48" contain />
              </template>
            </v-row>
            <!-- <v-row no-gutters align="center" justify="center">
                  <h1 class="text-center text-subtitle-1">{{ displayName }}</h1>
                </v-row> -->
            <v-row no-gutters align="center" justify="center">
              <slot />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!-- <PageFooter/> -->
  </v-app>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import RealmLogoImage from '@/components/RealmLogoImage.vue';
// import NavBar from '@/components/NavBar.vue';
// import PageFooter from '@/components/PageFooter.vue';

export default {
    name: 'RealmLayout',
    components: {
        RealmLogoImage,
        // NavBar,
        // PageFooter,
    },
    // data: () => ({
    //     displayName: null,
    // }),
    computed: {
        ...mapState({
            brandprofile: (state) => state.brandprofile,
            realmInfo: (state) => state.realmInfo,
        }),
        websiteURL() {
            return this.realmInfo?.website_url;
        },
        // displayName() {
        //     return this.realmInfo?.display_name;
        // },
    },
    // methods: {
    //     async loadRealmBranding() {
    //         // load public info about realm
    //         const result = await this.$client.realm(this.$route.params.realm).currentRealm.get();
    //         console.log(`loadRealmBranding result ${JSON.stringify(result)}`);
    //         if (result) {
    //             this.displayName = result.displayName;
    //         }
    //     },
    // },
    // created() {
    //     this.loadRealmBranding();
    // },
};
</script>

<style lang="css">
  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>
